export const ContemberHorizontal = ({ className }: { className?: string }) => (
	<svg className={className} width="170" height="43" viewBox="0 0 170 43" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
		<title>horizontal</title>
		<path d="M29.6405 22.3019L28.0841 21.0303L26.0902 22.6804C24.555 23.952 23.1716 25.4147 21.9717 27.0318L20.7965 28.6194L19.5754 26.9987C18.259 25.2493 16.7451 23.6727 15.0652 22.3019L13.5089 21.0303L14.4335 20.2769C16.5333 18.5642 18.4249 16.5944 20.0695 14.4077L20.793 13.4448L21.3541 14.1872C23.1081 16.5172 25.1267 18.6194 27.3677 20.4496L28.0806 21.0339L29.0052 20.2805C29.591 19.8028 30.1628 19.2993 30.7168 18.7811C29.7252 14.0181 25.6631 10.4532 20.7965 10.4532C15.1887 10.4532 10.6397 15.1905 10.6397 21.0303C10.6397 26.87 15.1887 31.6073 20.7965 31.6073C25.6773 31.6073 29.7534 28.0204 30.7274 23.2427C30.371 22.9193 30.011 22.6032 29.6369 22.3019H29.6405Z" />
		<path d="M39.748 20.9741C39.748 16.2654 43.4713 12.7126 48.5021 12.7126C50.0144 12.7126 51.7507 13.21 53.1042 14.1164V17.6249C51.8866 16.4247 50.2155 15.7467 48.6151 15.7467C45.3435 15.7467 43.1325 17.9648 43.1325 20.9741C43.1325 23.9834 45.3435 26.1572 48.6151 26.1572C50.2173 26.1572 51.8866 25.4775 53.1042 24.279V27.7875C51.7507 28.6921 50.0126 29.1913 48.5021 29.1913C43.4713 29.1895 39.748 25.658 39.748 20.9741Z" />
		<path d="M54.4788 23.1691C54.4788 19.7297 57.1856 17.1257 60.706 17.1257C64.2263 17.1257 66.9332 19.7279 66.9332 23.1691C66.9332 26.6104 64.2034 29.1895 60.706 29.1895C57.2086 29.1895 54.4788 26.5644 54.4788 23.1691ZM60.706 26.3148C62.4211 26.3148 63.7287 24.98 63.7287 23.1691C63.7287 21.3582 62.4194 20.0005 60.706 20.0005C58.9926 20.0005 57.6832 21.3582 57.6832 23.1691C57.6832 24.98 58.9926 26.3148 60.706 26.3148Z" />
		<path d="M79.9046 22.5124V28.9169H76.746V22.6257C76.746 21.0414 75.7984 20.0005 74.355 20.0005C72.9116 20.0005 71.964 21.0414 71.964 22.6257V28.9169H68.8054V22.5124C68.8054 19.2535 70.9935 17.1257 74.355 17.1257C77.7165 17.1257 79.9046 19.2535 79.9046 22.5124Z" />
		<path d="M86.1989 14.2969V17.3983H89.9892V20.0235H86.1989V24.1197C86.1989 25.7501 87.1235 26.428 88.117 26.428C88.9287 26.428 89.4934 26.2015 89.9892 25.9306V28.6921C89.1775 29.032 88.297 29.1895 87.5523 29.1895C84.9796 29.1895 83.0173 27.6282 83.0173 24.3003V20.0235H81.1681V17.3983H83.0173V14.2969H86.1989Z" />
		<path d="M90.9597 23.1691C90.9597 19.7969 93.3066 17.1487 96.7581 17.1487C100.436 17.1487 102.444 20.1137 101.925 23.8258H93.7583C94.0742 25.5004 95.4506 26.6103 97.6157 26.6103C99.0591 26.6103 100.458 26.0439 101.542 25.1163V27.8088C100.617 28.6231 99.0821 29.1895 97.0758 29.1895C93.4213 29.1895 90.9615 26.7679 90.9615 23.1691H90.9597ZM98.9003 21.7211C98.8103 20.4536 97.8398 19.526 96.5093 19.526C95.1788 19.526 94.2083 20.2731 93.8466 21.7211H98.9003Z" />
		<path d="M107.293 22.6929V28.9169H104.134V17.3983H107.293V19.1632C107.947 17.8497 109.278 17.1257 110.451 17.1257C112.098 17.1257 113.248 17.8037 113.88 19.0942C114.782 17.8037 116.024 17.1257 117.624 17.1257C120.174 17.1257 121.685 18.9366 121.685 22.2185V28.9169H118.526V22.4894C118.526 20.9511 117.781 20.0005 116.608 20.0005C115.322 20.0005 114.487 21.0414 114.487 22.6929V28.9169H111.328V22.4894C111.328 20.9511 110.584 20.0005 109.433 20.0005C108.124 20.0005 107.289 21.0414 107.289 22.6929H107.293Z" />
		<path d="M127.439 27.1751V28.9169H124.28V12.9835H127.439V19.1402C128.138 17.8497 129.447 17.1257 131.116 17.1257C134.229 17.1257 136.689 19.6836 136.689 23.1691C136.689 26.6546 134.229 29.1895 131.116 29.1895C129.447 29.1895 128.138 28.4655 127.439 27.1751ZM130.462 26.3148C132.177 26.3148 133.484 24.98 133.484 23.1691C133.484 21.3582 132.175 20.0005 130.462 20.0005C128.748 20.0005 127.439 21.3582 127.439 23.1691C127.439 24.98 128.748 26.3148 130.462 26.3148Z" />
		<path d="M138.086 23.1691C138.086 19.7969 140.433 17.1487 143.885 17.1487C147.562 17.1487 149.57 20.1137 149.051 23.8258H140.885C141.201 25.5004 142.577 26.6103 144.742 26.6103C146.186 26.6103 147.585 26.0439 148.669 25.1163V27.8088C147.744 28.6231 146.209 29.1895 144.202 29.1895C140.548 29.1895 138.088 26.7679 138.088 23.1691H138.086ZM146.027 21.7211C145.937 20.4536 144.966 19.526 143.636 19.526C142.305 19.526 141.335 20.2731 140.973 21.7211H146.027Z" />
		<path d="M156.315 20.0908C154.984 20.0908 154.262 21.1547 154.262 23.0098V28.9169H151.104V22.4894C151.104 19.2074 152.48 17.1257 155.729 17.1257C156.202 17.1257 157.285 17.239 157.714 17.3965V20.4059C157.488 20.2483 156.63 20.089 156.315 20.089V20.0908Z" />
	</svg>
)

export const ContemberIcon = ({ className }: { className?: string }) => (
	<svg className={className} width="43" height="43" viewBox="0 0 43 43" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
		<title>Contember</title>
		<path d="M29.6405 22.3019L28.0841 21.0303L26.0902 22.6804C24.555 23.952 23.1716 25.4147 21.9717 27.0318L20.7965 28.6194L19.5754 26.9987C18.259 25.2493 16.7451 23.6727 15.0652 22.3019L13.5089 21.0303L14.4335 20.2769C16.5333 18.5642 18.4249 16.5944 20.0695 14.4077L20.793 13.4448L21.3541 14.1872C23.1081 16.5172 25.1267 18.6194 27.3677 20.4496L28.0806 21.0339L29.0052 20.2805C29.591 19.8028 30.1628 19.2993 30.7168 18.7811C29.7252 14.0181 25.6631 10.4532 20.7965 10.4532C15.1887 10.4532 10.6397 15.1905 10.6397 21.0303C10.6397 26.87 15.1887 31.6073 20.7965 31.6073C25.6773 31.6073 29.7534 28.0204 30.7274 23.2427C30.371 22.9193 30.011 22.6032 29.6369 22.3019H29.6405Z" />
	</svg>
)
