import { SaveProjectRequestBody, SaveProjectResponseBody } from '@/pages/api/save-project'
import { selectProjectState, setProjectId, setProjectIsSaved } from '@/state/features/project/projectSlice'
import { selectSchemaState } from '@/state/features/schema/schemaSlice'
import { selectStructureState } from '@/state/features/structure/structureSlice'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { useRouter } from 'next/router'
import { useCallback, useRef } from 'react'
import { useApi } from '../useApi'

export const useSaveProject = () => {
	const router = useRouter()
	const dispatch = useAppDispatch()
	const project = useAppSelector(selectProjectState)
	const { dsl: schemaDsl } = useAppSelector(selectSchemaState)
	const { dsl: structureDsl, crudDsl: structureCrudDsl } = useAppSelector(selectStructureState)
	const organizationSlug = router.query.org as string
	const structure = { dsl: structureDsl, crudDsl: structureCrudDsl }
	const mutableRefObject = useRef({ project, schemaDsl: schemaDsl.present, structure, organizationSlug })
	mutableRefObject.current = { project, schemaDsl: schemaDsl.present, structure, organizationSlug }

	const [run, state] = useApi<SaveProjectRequestBody, SaveProjectResponseBody>('/api/save-project')

	return {
		run: useCallback(async () => {
			if (project.id === undefined) {
				return null
			}

			const result = await run(mutableRefObject.current)

			if (result.data.ok && result.data.result.id) {
				dispatch(setProjectId(result.data.result.id))
				dispatch(setProjectIsSaved(true))
				return result.data.result.id
			}

			return null
		}, [dispatch, project.id, run]),
		state,
	}
}
