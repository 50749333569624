import * as Sentry from '@sentry/nextjs'
import { useCallback, useState } from 'react'

export type FetchState<T = unknown> = { type: 'empty' } | { type: 'pending' } | { type: 'failed'; message: string } | { type: 'success'; data: T }

export type UseApiCallback<Req = unknown, Res = unknown> = (data: Req) => Promise<{ data: Res; response: Response }>

export type UseApi<Req = unknown, Res = unknown> = [fetch: UseApiCallback<Req, Res>, state: FetchState<Res>]

export const useApi = <Req = unknown, Res = unknown>(url: string, headers?: HeadersInit): UseApi<Req, Res> => {
	const [state, setState] = useState<FetchState<Res>>({ type: 'empty' })
	return [
		useCallback(
			async (reqData: Req) => {
				setState({ type: 'pending' })
				try {
					const res = await fetch(url, {
						method: 'POST',
						body: JSON.stringify(reqData),
						headers: {
							'Content-type': 'application/json',
							...headers,
						},
					})
					if (!res.ok) {
						const data = await res.json()
						Sentry.captureException(data.error ?? res)
						setState({ type: 'failed', message: data.error ?? res.statusText })
						return { response: res, data: { ok: false } }
					}
					const data = await res.json()
					setState({ type: 'success', data })
					return { response: res, data }
				} catch (e) {
					setState({ type: 'failed', message: (e as any).message })
					throw e
				}
			},
			[headers, url],
		),
		state,
	]
}
